import './App.scss';
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const LandingPage = React.lazy(() => import('./pages/Landing/Landing'));
const MintPage = React.lazy(() => import('./pages/Mint/Mint'));

function App() {
  return (
    <div>
      <Router>
        <Suspense fallback={<></>}>
          <Routes>
              <Route exact path="/" element={<LandingPage/>}/>
              <Route exact path="/mint" element={<MintPage/>}/>
          </Routes>
        </Suspense> 
      </Router>
    </div>
  );
}

export default App;
