import { createContext, useReducer } from "react";

const initialState = {
  wallet: {},
  balances: {
    eth: "0",
    usdc: "0",
    usdt: "0",
  },
  message: {
    showPopup: false,
    type: null,
    message: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "WALLET_UPDATED":
      return { ...state, wallet: action.payload };
    case "BALANCES_UPDATED":
      return { ...state, balances: action.payload };
    case "MESSAGE_UPDATED":
      return { ...state, message: action.payload };
    default:
      return { ...state };
  }
};

export const Store = createContext(initialState);
export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
};
